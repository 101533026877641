import {
	Box,
	Button,
	Flex,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Text,
	chakra,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import DropdownIcon from '../Icons/DropdownIcon';
import SelectCustom from '../UI/SelectCustom';
import Container from '../../layouts/Container';
import RefreshIcon from '../Icons/RefreshIcon';

const TextExpend = styled(Text)`
	word-spacing: 10px;
`;

const InputCustom = styled(Input)`
	border: 1px solid #ffffff1e;
	color: #737283;
	::placeholder,
	::-webkit-input-placeholder {
		color: #737283;
	}
	:-ms-input-placeholder {
		color: #737283;
	}
`;

const RefreshButton = chakra(Button, {
	baseStyle: {
		background: '#484756',
		borderRadius: '5px',
		padding: {
			base: '5px 25px',
		},
	},
});

const RefreshText = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	margin-left: 10px;
	color: #ffffff;
`;

const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	margin-bottom: 10px;
`;

const fiats = [
	{ key: 'USDT', value: 'USDT', icon: 'money-icon' },
	{ key: 'ETH', value: 'ETH', icon: 'money-icon' },
];
const payments = [
	{ key: 'all', value: 'All payment' },
	{ key: 'cash', value: 'Cash in Person' },
	{ key: 'bank', value: 'Bank Tranfer' },
];
const regions = [{ key: 'all', value: 'All Regions', icon: 'global-icon' }];

const fiatCurrent = fiats[0];
const paymentCurrent = payments[0];
const regionCurrent = regions[0];

export default function FilterAndRefresh() {
	return (
		<Container>
			<Flex justifyContent={'space-between'} alignItems={'flex-end'}>
				<Flex gap={'20px'}>
					<Box>
						<Label>Amount</Label>
						<InputGroup size="md">
							<InputCustom
								pr="7.5rem"
								placeholder="Enter amount..."
								focusBorderColor="#FFFFFF1E"
								size={'md'}
							/>
							<InputRightElement width={'7.5rem'}>
								<TextExpend color={'#737283'} fontSize={{ base: '14px' }}>
									USD | Search
								</TextExpend>
							</InputRightElement>
						</InputGroup>
					</Box>

					<Box>
						<Label>Fiat</Label>
						<SelectCustom value={fiatCurrent} list={fiats} />
					</Box>

					<Box>
						<Label>Payment</Label>
						<SelectCustom value={paymentCurrent} list={payments} />
					</Box>

					<Box>
						<Label>Payment</Label>
						<SelectCustom value={regionCurrent} list={regions} />
					</Box>
				</Flex>

				<RefreshButton>
					<RefreshIcon />
					<RefreshText>Not now</RefreshText>
				</RefreshButton>
			</Flex>
		</Container>
	);
}

import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import Slider from 'react-slick';
import Container from '../../layouts/Container';

const AdsCarousel = () => {
	const bannerIds: number[] = [];
	for (let i = 0; i < 5; i++) {
		bannerIds.push(i + 1);
	}
	const slickSettings = {
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		slidesToShow: 4,
		arrows: false,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<Container>
			<Box>
				<Box
					data-aos="fade-up"
					data-aos-duration="1000"
					sx={{
						'.slick-dots': {
							transform: 'translateY(1em)',
						},
						'.slick-dots li button': {
							background: '#737283',
						},
						'.slick-slide > div': {
							margin: '0 10px',
						},
						'.slick-list': {
							margin: '0 -10px',
						},
					}}
				>
					<Slider {...slickSettings}>
						{bannerIds.map((id) => (
							<Image
								key={id}
								w={'100%'}
								h={'100%'}
								objectFit={'cover'}
								src={`/assets/images/homepage/banner-${id}.png`}
							/>
						))}
					</Slider>
				</Box>
			</Box>
		</Container>
	);
};

export default AdsCarousel;

import React from 'react';
import { Link } from 'react-router-dom';
import Authentication from '.';
const formFields = [
	{
		key: 1,
		lable: 'Email or Phone number',
		name: 'emailOrPhone',
		type: 'text',
		placeholde: 'Enter your Email or Phone number...',
	},
];

const Login = () => {
	const handleSubmit = (value: any) => {
		console.log(value);
	};
	return (
		<Authentication
			title={'Login'}
			titleButton={'Next'}
			subtitle={
				<>
					No Account?{' '}
					<Link
						style={{ color: '#FFBF1A', marginLeft: '4px' }}
						to={'/register'}
					>
						Sign up
					</Link>
				</>
			}
			onSubmit={handleSubmit}
			formFields={formFields}
		/>
	);
};

export default Login;

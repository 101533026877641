export default function FilterIcon() {
	return (
		<svg
			width={15}
			height={15}
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.1818 0.625H1.81833C1.52985 0.625227 1.24764 0.709182 1.00595 0.866675C0.764263 1.02417 0.573482 1.24843 0.456764 1.51224C0.340047 1.77605 0.302411 2.06806 0.348426 2.35285C0.39444 2.63763 0.522125 2.90293 0.715986 3.11656L5.64067 8.53266V13.6406C5.64072 13.7405 5.6689 13.8383 5.72199 13.9229C5.77508 14.0074 5.85092 14.0753 5.94083 14.1187C6.01268 14.1542 6.09181 14.1724 6.17192 14.1719C6.29267 14.1718 6.40978 14.1306 6.50395 14.055L7.50005 13.2581L9.1602 11.93C9.22233 11.8803 9.27248 11.8172 9.30696 11.7455C9.34145 11.6738 9.35937 11.5952 9.35942 11.5156V8.53266L14.2841 3.11656C14.478 2.90293 14.6057 2.63763 14.6517 2.35285C14.6977 2.06806 14.66 1.77605 14.5433 1.51224C14.4266 1.24843 14.2358 1.02417 13.9941 0.866675C13.7525 0.709182 13.4702 0.625227 13.1818 0.625Z"
				fill="white"
			/>
		</svg>
	);
}

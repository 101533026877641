import { Box, Flex, Show, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import FilterIcon from '../Icons/FilterIcon';
import RefreshIcon from '../Icons/RefreshIcon';
import { SwitchCustom } from '../UI/SwitchCustom';
import Container from '../../layouts/Container';

const CoinTabs = styled(Flex)``;

const ActiveMark = styled(Box)`
	position: absolute;
	bottom: 3px;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	border-radius: 2px 2px 0px 0px;
`;

const cryptos = [
	{ key: 'usdt', symbol: 'usdt' },
	{ key: 'btc', symbol: 'btc' },
	{ key: 'busd', symbol: 'busd' },
	{ key: 'bnb', symbol: 'bnb' },
	{ key: 'eth', symbol: 'eth' },
	{ key: 'usdc', symbol: 'usdc' },
];

export default function CryptoTabs() {
	const [activeTab, setActiveTab] = useState(cryptos[0]);
	const switchValues = ['Buy', 'Sell'];
	const [activeValue, setActiveValue] = useState('Buy');
	return (
		<Box
			background={'#09122980'}
			height={{ base: 'auto', lg: '56px' }}
			padding={{ base: '12px 0px 0px', lg: '0px' }}
		>
			<Container>
				<Flex
					height={'100%'}
					alignItems={{ lg: 'center' }}
					direction={{ base: 'column', lg: 'row' }}
					gap={{ base: '0px', lg: '30px' }}
				>
					<Flex justifyContent={{ base: 'space-between', lg: 'flex-start' }}>
						<SwitchCustom
							values={switchValues}
							activeValue={activeValue}
							setActiveValue={setActiveValue}
						/>

						<Show below="lg">
							<Flex alignItems={'center'} gap={'10px'}>
								<Box>
									<RefreshIcon />
								</Box>
								<Box>
									<FilterIcon />
								</Box>
							</Flex>
						</Show>
					</Flex>
					<CoinTabs
						height={{ lg: '100%', base: '56px' }}
						gap={'10px'}
						maxWidth={{ base: '100vw', lg: 'auto' }}
						overflow={'auto'}
					>
						{cryptos.map((crypto) => {
							return (
								<Flex
									key={crypto.key}
									height={'100%'}
									alignItems={'center'}
									padding={{ base: '0 10px', lg: '0 20px' }}
									position={'relative'}
									cursor={'pointer'}
									onClick={() => {
										setActiveTab(crypto);
									}}
								>
									<Text textTransform={'uppercase'} color={'#737283'}>
										{crypto.symbol}
									</Text>
									{activeTab.key === crypto.key && <ActiveMark />}
								</Flex>
							);
						})}
					</CoinTabs>
				</Flex>
			</Container>
		</Box>
	);
}

import { Box, Flex, Show, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MoonDarkIcon from '../../components/Icons/MoonDarkIcon';
import PrimaryButton from '../../components/UI/PrimaryButton';
import TransparentButton from '../../components/UI/TransparentButton';
import MobileMenu from './MobileMenu';
import { LinkActive, LinkItem, NavCenterWrapper, NavWrapper } from './styles';
import { ROUTES } from '../../routes/config';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const links = [
	{ title: 'Home', link: ROUTES.HOME },
	{ title: 'Buy Sell Crypto', link: ROUTES.BUY_SELL },
	{ title: 'Metagames', link: '' },
	{ title: 'Telegram Support', link: '' },
	{ title: 'FAQs', link: '' },
];
export default function TopNav() {
	const { toggleColorMode } = useColorMode();
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<Box position={'fixed'} zIndex={100} background={'#050713'} width={'100%'}>
			<NavWrapper padding={{ base: '16px 18px', lg: '16px 20px' }}>
				<Flex gap={10} alignItems={'center'} justifyContent={'space-between'}>
					<Box w="210px">
						<img src="/assets/images/logo.svg" alt="logo" />
					</Box>

					<Show above="lg">
						<NavCenterWrapper
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Flex alignItems={'center'} gap={5}>
								{links.map((link) => {
									if (link.link === location.pathname)
										return (
											<LinkActive key={link.title}>{link.title}</LinkActive>
										);
									return (
										<LinkItem
											key={link.title}
											onClick={() => {
												navigate(link.link);
												// setLinkActive(link.title);
											}}
										>
											{link.title}
										</LinkItem>
									);
								})}
							</Flex>

							<Flex gap={5}>
								<Link to={'/login'}>
									<TransparentButton>Log In</TransparentButton>
								</Link>
								<Link to={'/register'}>
									<PrimaryButton>Sign up</PrimaryButton>
								</Link>
							</Flex>
						</NavCenterWrapper>

						<Flex alignItems={'center'} gap={5}>
							<p>Download</p>
							<Box onClick={() => toggleColorMode()}>
								<MoonDarkIcon />
							</Box>
							<Flex alignItems={'center'} gap={2}>
								<p>English</p>|<p>USD</p>
							</Flex>
						</Flex>
					</Show>

					<Show below="lg">
						<MobileMenu />
					</Show>
				</Flex>
			</NavWrapper>
		</Box>
	);
}

import { Box, Flex, Text } from '@chakra-ui/react';
import { findIndex } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface PropsType {
	values: string[];
	activeValue: string;
	setActiveValue: any;
}

export const SwitchCustom = ({
	values,
	activeValue,
	setActiveValue,
}: PropsType) => {
	const width = 107;
	const [wrapperWidth, setWrapperWidth] = useState(width);
	const wrapperRef = useRef<any>();

	useEffect(() => {
		if (wrapperRef.current) {
			setWrapperWidth(wrapperRef.current?.clientWidth);
		}
	}, []);

	return (
		<Box
			background={'#ffffff'}
			borderRadius={'5px'}
			width={{ base: `107px` }}
			height={{ base: '32px' }}
			padding={'3px'}
		>
			<Flex
				position={'relative'}
				alignItems={'center'}
				height={'100%'}
				ref={wrapperRef}
			>
				{values.map((value) => {
					const isActive = value === activeValue;
					return (
						<Box
							width={'50%'}
							position={'relative'}
							zIndex={2}
							cursor={'pointer'}
							key={value}
							onClick={() => {
								setActiveValue(value);
							}}
						>
							<Text
								color={isActive ? '#ffffff' : '#050713'}
								textAlign={'center'}
								lineHeight={'16px'}
								fontWeight={isActive ? 500 : 400}
							>
								{value}
							</Text>
						</Box>
					);
				})}

				<motion.div
					style={{ position: 'absolute', width: '50%', height: '100%' }}
					animate={{
						left:
							(findIndex(values, (value) => value === activeValue) *
								wrapperWidth) /
							2,
					}}
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 30 },
						transform: { duration: 0.2 },
					}}
				>
					<Box
						background={'#03A66D'}
						width={'100%'}
						height={'100%'}
						borderRadius={'4px'}
					/>
				</motion.div>
			</Flex>
		</Box>
	);
};

// theme.ts

// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

// 2. Add your color mode config
const config: ThemeConfig = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};
// 3. extend the theme
const theme = extendTheme({
	config,
	styles: {
		global: (props: StyleFunctionProps) => ({
			body: {
				bg: mode('white', 'black')(props),
			},
		}),
	},
});

export default theme;

import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';
import { ColumnsBuySellTable, DataBuySellTable } from './interface';

const BuySellTable = ({
	dataTable,
	columns,
	isBorder = true,
}: {
	dataTable: DataBuySellTable[];
	columns: ColumnsBuySellTable[];
	isBorder?: boolean;
}) => {
	return (
		<TableContainer mt={'10px'}>
			<Table variant={'simple'} colorScheme={'blackAlpha'}>
				<Thead>
					<Tr>
						{columns.map((col) => (
							<Th
								color={'#737283'}
								textTransform={'capitalize'}
								key={col.key}
								padding={'20px 0px'}
								isNumeric={col.isNumeric}
								fontSize={{ base: '14px' }}
								fontWeight={400}
								border={'none'}
								fontFamily={'Roboto'}
								textAlign={col.align ? col.align : 'start'}
							>
								{col.title}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{dataTable.length > 0 &&
						dataTable.map((data: any) => (
							<Tr key={data.key}>
								{columns.map((col) => (
									<Td
										key={col.key}
										isNumeric={col.isNumeric}
										borderBottom={'none'}
										borderTop={'1px solid'}
										borderColor={isBorder ? '#FFFFFF1E' : '#ffffff00'}
										padding={'16px 0px'}
									>
										{col.render ? col.render(data) : data[col.key]}
									</Td>
								))}
							</Tr>
						))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default BuySellTable;

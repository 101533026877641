import {
	Box,
	Button,
	Drawer,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import MenuIcon from '../../components/Icons/MenuIcon';
import TransparentButton from '../../components/UI/TransparentButton';

export default function MobileMenu() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef<any>();

	return (
		<>
			<Box
				ref={btnRef}
				onClick={onOpen}
				px="0px"
				width={'max-content'}
				cursor={'pointer'}
			>
				<MenuIcon />
			</Box>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Create your account</DrawerHeader>
				</DrawerContent>
			</Drawer>
		</>
	);
}

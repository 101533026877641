import {
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import DropdownIcon from '../Icons/DropdownIcon';
import QuestionIcon from '../Icons/QuestionIcon';
import Container from '../../layouts/Container';

const types = ['Express', 'P2P', 'Block'];

const TabActive = styled(Box)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	font-weight: 500;
`;

export default function Type() {
	const [typeActive, setTypeActive] = useState<string>(types[0]);

	return (
		<Box
			background={'#091229'}
			height={{ base: '56px' }}
			padding={{ base: '12px 0px 0px', lg: '0px' }}
		>
			<Container>
				<Flex
					height={'100%'}
					alignItems={{ base: 'center' }}
					justifyContent={'space-between'}
				>
					<Flex gap={{ lg: '50px', base: '30px' }}>
						{types.map((type) => {
							if (type === typeActive) {
								return (
									<TabActive
										key={type}
										fontSize={{ base: '14px' }}
										cursor={'pointer'}
									>
										{type}
									</TabActive>
								);
							}
							return (
								<Box
									key={type}
									fontSize={{ base: '14px' }}
									cursor={'pointer'}
									onClick={() => {
										setTypeActive(type);
									}}
								>
									{type}
								</Box>
							);
						})}
					</Flex>

					<Menu>
						<MenuButton>
							<Flex alignItems={'center'} gap={{ base: '8px ' }}>
								<QuestionIcon />
								<Text color={'#737283'} fontSize={{ base: '14px' }}>
									User guide
								</Text>
								<DropdownIcon />
							</Flex>
						</MenuButton>
						<MenuList>
							<MenuItem>Download</MenuItem>
							<MenuItem onClick={() => alert('Kagebunshin')}>
								Create a Copy
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Container>
		</Box>
	);
}

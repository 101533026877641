import { createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import BuySellPage from '../pages/buySell';
import { ROUTES } from './config';
import RegisterPage from '../pages/register';

export const router = createBrowserRouter([
	{
		path: '',
		element: <MainLayout />,
		children: [
			{
				path: ROUTES.HOME,
				element: <HomePage />,
			},
			{
				path: ROUTES.BUY_SELL,
				element: <BuySellPage />,
			},
			{
				path: ROUTES.LOGIN,
				element: <LoginPage />,
			},
			{
				path: ROUTES.REGISTER,
				element: <RegisterPage />,
			},
		],
	},
]);

import { RouterProvider } from 'react-router-dom';
import './App.css';
import { router } from './routes';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function getLibrary(provider: any): Web3Provider {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12_000;
	return library;
}

function App() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<RouterProvider router={router} />
		</Web3ReactProvider>
	);
}

export default App;

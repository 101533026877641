import { Box, Text, chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { breakpoints } from '../../config/theme';

const BannerWrapper = styled(Box)`
	background: url('/assets/images/buy-sell-bg.png');
	background-position: 80% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	@media (min-width: ${breakpoints.lg}) {
		background-size: 100% 100%;
	}
`;

const Title = styled(Text)`
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	max-width: 963px;
	margin: auto;
	@media (min-width: ${breakpoints.lg}) {
		font-size: 30px;
		line-height: 48px;
	}
`;

const Description = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	max-width: 839px;
	margin: 10px auto 0;
	color: #bab9c9;
	@media (min-width: ${breakpoints.lg}) {
		font-size: 18px;
		line-height: 28px;
	}
`;

export default function Banner() {
	return (
		<BannerWrapper>
			<Box
				padding={{ base: '70px 18px 40px', lg: '120px 20px 60px' }}
				maxWidth={'1248px'}
				mx={'auto'}
			>
				<Title>
					Buy and Sell TetherUS (USDT) With Your Preferred Payment Method
				</Title>
				<Description>
					Buy and sell TetherUS safely and easily on Metaniex P2P. Find the best
					offer below and buy and sell USDT with Your Preferred Payment Methods
					today.
				</Description>
			</Box>
		</BannerWrapper>
	);
}

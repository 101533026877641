import React from 'react';
import {
	COInformation,
	FooterFlex,
	FooterHeading,
	FooterTitle,
	FooterWrapper,
	SecondaryText,
} from './styles';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const socials = [
	{ name: 'facebook', href: '' },
	{ name: 'twitter', href: '' },
	{ name: 'instagram', href: '' },
	{ name: 'discord', href: '' },
];

export default function Footer() {
	return (
		<Box>
			<FooterWrapper padding={{ base: '30px 18px', lg: '80px 20px' }}>
				<FooterFlex
					gap={{ lg: '130px', base: '10px' }}
					direction={{ lg: 'row', base: 'column' }}
				>
					<Box textAlign={{ base: 'center', lg: 'left' }}>
						<Image
							src="/assets/images/logo.svg"
							alt="logo"
							margin={{ base: 'auto', lg: 0 }}
						/>
						<FooterTitle margin={{ base: '10px 0', lg: '20px 0 0 0' }}>
							Join the world’s largest crypto exchange!
						</FooterTitle>
						<SecondaryText>
							Buy, trade, and hold 350+ cryptocurrencies on Metaniex
						</SecondaryText>
					</Box>

					<Flex
						gap={{ lg: '130px', base: '20px' }}
						justifyContent={{ lg: 'flex-start', base: 'space-between' }}
					>
						<Box>
							<FooterHeading>Categories</FooterHeading>

							<SecondaryText>Buy Sell</SecondaryText>
							<SecondaryText>Metagames</SecondaryText>
							<SecondaryText>Support</SecondaryText>
							<SecondaryText>FAQs</SecondaryText>
						</Box>

						<Box textAlign={{ base: 'right', lg: 'left' }}>
							<FooterHeading>Quick Links</FooterHeading>

							<SecondaryText>Marketplace</SecondaryText>
							<SecondaryText>Pitch deck</SecondaryText>
							<SecondaryText>Tokenomics</SecondaryText>
							<SecondaryText>Download</SecondaryText>
						</Box>
					</Flex>

					<Box textAlign={{ base: 'center', lg: 'left' }}>
						<FooterHeading>Get in touch</FooterHeading>

						<SecondaryText>
							For product support or questions <br /> please join our Social:
						</SecondaryText>

						<Flex
							gap={'20px'}
							mt={'15px'}
							justifyContent={{ base: 'center', lg: 'flex-start' }}
						>
							{socials.map((social) => (
								<a
									key={social.name}
									href={social.href}
									target={'_blank'}
									rel="noreferrer"
								>
									<img src={`/assets/images/socials/${social.name}.svg`} />
								</a>
							))}
						</Flex>
					</Box>
				</FooterFlex>
			</FooterWrapper>

			<COInformation>
				<Text fontSize={{ base: '12px', lg: '16px' }}>
					Copyright © 2023 Metaniex. All rights reserved
				</Text>
			</COInformation>
		</Box>
	);
}

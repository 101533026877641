import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import { BodyWrapper, LayoutWrapper } from './styles';
import Footer from './Footer';
import { Box } from '@chakra-ui/react';

export default function MainLayout() {
	const topNavRef = useRef<any>();
	const footerRef = useRef<any>();
	const [bodyHeight, setBodyHeight] = useState(0);

	useEffect(() => {
		if (topNavRef.current && footerRef.current) {
			setBodyHeight(footerRef.current?.clientHeight);
		}
	}, []);

	return (
		<LayoutWrapper>
			<Box ref={topNavRef}>
				<TopNav />
			</Box>

			<BodyWrapper minHeight={bodyHeight}>
				<Outlet />
			</BodyWrapper>

			<div ref={footerRef}>
				<Footer />
			</div>
		</LayoutWrapper>
	);
}

import React from 'react';
import { Link } from 'react-router-dom';
import Authentication from '.';

const formFields = [
	{
		key: 1,
		lable: 'Email or Phone number',
		name: 'emailOrPhone',
		type: 'email',
		placeholde: 'Enter your Email or Phone number...',
	},
	{
		key: 2,
		lable: 'Password',
		name: 'password',
		type: 'password',
		placeholde: 'Enter your password...',
	},
	{
		key: 3,
		lable: 'Invitation code (Optional)',
		name: 'invitationCode',
		type: 'text',
		placeholde: 'Enter your code...',
	},
	{
		key: 4,
		lable: (
			<>
				I have read & agreed Metaniex,
				<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/">
					Terms of Service
				</Link>
			</>
		),
		name: 'invitationCode',
		type: 'checkbox',
		placeholde: 'Enter your code...',
	},
];

const Register = () => {
	const handleSubmit = (value: any) => {
		console.log(value);
	};
	return (
		<Authentication
			title={'Welcome to register'}
			titleButton={'Sign up'}
			subtitle={
				<>
					Already have an account?
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/login">
						Log in
					</Link>
				</>
			}
			onSubmit={handleSubmit}
			formFields={formFields}
		/>
	);
};

export default Register;

import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Show,
	Tag,
	TagLabel,
	Text,
	chakra,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import BuySellTable from './BuySellTable';
import styled from '@emotion/styled';
import { ColumnsBuySellTable, DataBuySellTable } from './interface';
import Pagination from '../UI/Pagination';

const TitleHasTagWrapper = chakra(Flex, {
	baseStyle: {
		alignItems: 'center',
		gap: '10px',
	},
});

const Title = chakra(Text, {
	baseStyle: {
		fontSize: '14px',
		fontWeight: 400,
	},
});

const GradientTag = styled(TagLabel)`
	font-size: 14px;
	font-weight: 400;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;

const PrimaryTag = styled(TagLabel)`
	font-size: 14px;
	font-weight: 400;
	color: #03a66d;
`;

const PrimaryText = styled(Text)`
	color: #ffffff;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
`;

const SecondaryText = styled(Text)`
	color: #737283;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
`;

const TagCustom = chakra(Tag, {
	baseStyle: {
		borderRadius: '3px',
	},
});

const PaymentCard = chakra(Flex, {
	baseStyle: {
		w: '117px',
		h: '26px',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '3px',
	},
});

const CashPaymentText = chakra(Text, {
	baseStyle: {
		fontSize: { base: '12px', lg: '14px' },
		fontWeight: 400,
		color: '#03a66d',
		lineHeight: '14px',
	},
});

const BankPaymentText = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;

const Rate = styled(Text)`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #737283;
`;

const columns: ColumnsBuySellTable[] = [
	{
		key: 'name',
		title: 'Advertisers (Completion rate)',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap="10px">
				<Flex alignItems={'center'} gap={'8px'}>
					<img src={data['avatar']} alt="avatar" />
					<Text>{data['name']}</Text>
					<img src={'/assets/images/tick.svg'} alt="tick" />
				</Flex>

				<Rate>{`${data['order']} orders | ${data['completion']} completion`}</Rate>
			</Flex>
		),
	},
	{
		key: 'price',
		title: (
			<TitleHasTagWrapper>
				<Title>Price</Title>
				<TagCustom size={'md'} variant="subtle" background={'#FEF6D84D'}>
					<GradientTag>Lowest to highest</GradientTag>
				</TagCustom>
			</TitleHasTagWrapper>
		),
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap={'5px'}>
				<PrimaryText>{data['price']}</PrimaryText>
				<SecondaryText>{data['currency']}</SecondaryText>
			</Flex>
		),
	},
	{
		key: 'limit_available',
		title: 'Limit / Available',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<Flex direction={'column'} gap={'5px'}>
				<Flex alignItems={'flex-end'} gap={{ base: '10px' }}>
					<SecondaryText>Available:</SecondaryText>
					<PrimaryText>{data['available']}</PrimaryText>
				</Flex>
				<Flex alignItems={'flex-end'} gap={{ base: '10px' }}>
					<SecondaryText>Limit:</SecondaryText>
					<PrimaryText>{data['limit']}</PrimaryText>
				</Flex>
			</Flex>
		),
	},
	{
		key: 'payment',
		title: 'Payment',
		isNumeric: false,
		align: 'start',
		render: (data: any) => (
			<PaymentCard
				background={data['paymentType'] === 'cash' ? '#CBFFD64D' : '#FEF6D84D'}
			>
				{data['paymentType'] === 'cash' ? (
					<CashPaymentText>{data['payment']}</CashPaymentText>
				) : (
					<BankPaymentText>{data['payment']}</BankPaymentText>
				)}
			</PaymentCard>
		),
	},
	{
		key: 'trade',
		title: (
			<TitleHasTagWrapper justifyContent={{ base: 'center' }}>
				<Title>Trade</Title>
				<TagCustom size={'md'} variant="subtle" background={'#CBFFD64D'}>
					<PrimaryTag>0 Fee</PrimaryTag>
				</TagCustom>
			</TitleHasTagWrapper>
		),
		isNumeric: true,
		align: 'center',
		render: (data: any) => (
			<Button
				fontSize={'16px'}
				w={{ base: '148px' }}
				h={{ base: '38px' }}
				lineHeight={'24px'}
				bg={'#03A66D'}
				fontWeight={400}
				color={'white'}
			>
				Buy USDT
			</Button>
		),
	},
];

const dataTable: DataBuySellTable[] = [
	{
		key: 1,
		name: 'iCoinCripto',
		price: '0,996',
		available: '299.16 USDT',
		limit: '$50.00 - $100.00',
		payment: 'Cash in Person',
		paymentType: 'cash',
		avatar: '/assets/images/avatar.svg',
		currency: 'USD',
		order: '37',
		completion: '94.90%',
	},
	{
		key: 2,
		name: 'iCoinCripto',
		price: '0,996',
		available: '299.16 USDT',
		limit: '$50.00 - $100.00',
		payment: 'Bank Tranfer',
		paymentType: 'bank',
		avatar: '/assets/images/avatar.svg',
		currency: 'USD',
		order: '37',
		completion: '94.90%',
	},
];

const pagination = { total: 200, pageSize: 5 };

export default function TradeData() {
	const [page, setPage] = useState(1);
	return (
		<div>
			<Show above="sm">
				<BuySellTable dataTable={dataTable} columns={columns} isBorder />
			</Show>
			<Show below="sm">
				{dataTable.map((data) => (
					<BuySellBlock key={data.key} data={data} />
				))}
			</Show>

			<Flex justifyContent={'center'} py={'20px'}>
				<Pagination
					handleChangePage={(page) => {
						setPage(page);
					}}
					page={page}
					pagination={pagination}
				/>
			</Flex>
		</div>
	);
}

const BuySellBlock = ({ data }: { data: DataBuySellTable }) => {
	return (
		<Box py="20px" borderBottom={'1px solid #FFFFFF1E'}>
			<Grid templateColumns="repeat(2, 1fr)" gap={1} w={'100%'}>
				<GridItem w={'100%'}>
					<Flex alignItems={'center'} gap={'8px'}>
						<img src={data['avatar']} alt="avatar" />
						<Text>{data['name']}</Text>
						<img src={'/assets/images/tick.svg'} alt="tick" />
					</Flex>
					<Flex my="10px">
						<Box w={'46px'}>
							<SecondaryText>Price:</SecondaryText>
						</Box>
						<Box ml="5px">
							<PrimaryText>
								{data['price']} {data['currency']}
							</PrimaryText>
						</Box>
					</Flex>
					<Flex my="10px">
						<Box w={'46px'}>
							<SecondaryText>Amount:</SecondaryText>
						</Box>
						<Box ml="5px">
							<PrimaryText>{data['available']}</PrimaryText>
						</Box>
					</Flex>
					<Flex my="10px">
						<Box w={'46px'}>
							<SecondaryText>Limit:</SecondaryText>
						</Box>
						<Box ml="5px">
							<PrimaryText>{data['limit']}</PrimaryText>
						</Box>
					</Flex>
					<PaymentCard background={'#CBFFD64D'} mt={{ base: '15px' }}>
						<CashPaymentText>Cash in Person</CashPaymentText>
					</PaymentCard>
				</GridItem>

				<GridItem w={'100%'}>
					<Flex
						flexDirection={'column'}
						justifyContent={'space-between'}
						alignItems={'flex-end'}
						h={'100%'}
					>
						<Rate textAlign={'right'}>
							{data['order']} orders | {data['completion']} completion
						</Rate>
						<Button
							fontSize={'16px'}
							w={{ base: '100%' }}
							maxW={'148px'}
							h={{ base: '38px' }}
							lineHeight={'24px'}
							bg={'#03A66D'}
							fontWeight={400}
							color={'white'}
						>
							Buy USDT
						</Button>
					</Flex>
				</GridItem>
			</Grid>
		</Box>
	);
};

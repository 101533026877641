import React from 'react';
import Container from '../../layouts/Container';
import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';

const CHANGED = {
	UP: 'up',
	DOWN: 'down',
};

const listToken = [
	{
		name: 'APT/USDT',
		ranking: '-1.05%',
		value: '10.6124',
		currency: '$10.61',
		changed: 'down',
	},
	{
		name: 'BTC/USDT',
		ranking: '+0.44%',
		value: '29,788.66',
		currency: '$29,788.66',
		changed: 'up',
	},
	{
		name: 'ETH/USDT',
		ranking: '+0.47%',
		value: '1,929.25',
		currency: '$1,929.25',
		changed: 'up',
	},
	{
		name: 'BNB/USDT',
		ranking: '-2.05%',
		value: '331,50',
		currency: '$331,50',
		changed: 'down',
	},
	{
		name: 'SOL/USDT',
		ranking: '-0,09%',
		value: '23,67',
		currency: '$23,67',
		changed: 'down',
	},
];

const Statistical = () => {
	return (
		<Box my={'50px'}>
			<Container>
				<List
					display={'flex'}
					justifyContent={'space-between'}
					overflowX={'auto'}
					overflowY={'hidden'}
				>
					{listToken.map((token, index) => (
						<ListItem
							key={index + 1}
							mr={{ base: '20px', md: '0' }}
							py={2}
							data-aos="fade-up"
						>
							<Flex alignItems={'flex-end'}>
								<Text fontSize={{ base: '14px', md: '20px' }}>
									{token.name}
								</Text>
								<Text
									fontSize={{ base: '12px', md: '16px' }}
									ml={'8px'}
									lineHeight={'20px'}
									color={token.changed === CHANGED.UP ? '#21FF0E' : '#FF0E39'}
								>
									{token.ranking}
								</Text>
							</Flex>
							<Text fontSize={{ base: '20px', md: '30px' }} lineHeight={'48px'}>
								{token.value}
							</Text>
							<Text
								lineHeight={'20px'}
								fontSize={{ base: '14px', md: '16px' }}
								color={'#737283'}
							>
								{token.currency}
							</Text>
						</ListItem>
					))}
				</List>
			</Container>
		</Box>
	);
};

export default Statistical;

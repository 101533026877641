import {
	Button,
	Flex,
	Img,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	chakra,
} from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import DropdownIcon from '../Icons/DropdownIcon';

interface Option {
	key: string | number;
	value: string;
	icon?: string;
}

const ValueOption = chakra(Text, {
	baseStyle: {
		color: '#737283',
		fontSize: '14px',
		fontWeight: 400,
		flex: 'none',
	},
});

interface PropsType {
	value: Option;
	list: Option[];
}

export default function SelectCustom({ list, value }: PropsType) {
	const buttonRef = useRef<any>();
	const [widthOptions, setWidthOptions] = useState(0);

	useEffect(() => {
		if (buttonRef.current) {
			setWidthOptions(buttonRef?.current?.clientWidth);
		}
	}, []);

	return (
		<Menu>
			<MenuButton
				as={Button}
				// leftIcon={
				// 	<Img
				// 		src={`/assets/images/icons/${list[0].icon}.svg`}
				// 		alt={list[0].icon}
				// 		mr={'5px'}
				// 	/>
				// }
				rightIcon={<DropdownIcon />}
				ref={buttonRef}
				variant="outline"
				padding={'0 10px'}
				// display={'flex'}
				// justifyContent={'space-between'}
				// gap={'15px'}
				// w={width}
			>
				<Flex alignItems={'center'} gap={'5px'} pr={'20px'}>
					{value.icon && (
						<Img
							src={`/assets/images/icons/${list[0].icon}.svg`}
							alt={value.icon}
							mr={'5px'}
						/>
					)}
					<ValueOption>{value.value}</ValueOption>
				</Flex>
			</MenuButton>
			<MenuList
				w={value.icon ? widthOptions + 18 : widthOptions + 2}
				minW={0}
				padding={0}
			>
				{list.map((option) => (
					<MenuItem key={option.key} padding={'5px 10px'}>
						<Flex alignItems={'center'} gap={'5px'} pr={'20px'}>
							{option.icon && (
								<Img
									src={`/assets/images/icons/${option.icon}.svg`}
									alt={option.icon}
									mr={'5px'}
								/>
							)}
							<ValueOption>{option.value}</ValueOption>
						</Flex>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
}

import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	background-color: #091229;
	padding-block: 100px;
`;

const listJourney = [
	{
		id: 1,
		title: '1. Create your account',
		description:
			'Create your account in just one simple step by linking your Google, Facebook, Apple ID, or Email account',
	},
	{
		id: 2,
		title: '2. Verify your account',
		description:
			'To enhance the security of your account and begin trading, please verify your Google Authenticator',
	},
	{
		id: 3,
		title: '3. Start trading crypto',
		description:
			'Purchase USDT and other cryptocurrencies with your local currency through your bank account',
	},
];

const StartYourJourney = () => {
	return (
		<Wrapper>
			<Container>
				<Box
					fontSize={{ base: '20px', md: '30px' }}
					textAlign={'center'}
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					Start your journey
				</Box>
				<Box
					maxW={'889px'}
					mx={'auto'}
					textAlign={'center'}
					color={'#737283'}
					fontWeight={400}
					fontSize={{ base: '16px', md: '18px' }}
					mb={'20px'}
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					Metaniex is one the safest cryptocurrency exchanges where you can buy,
					sell, store, and accrue staking rewards on cypto and other
					cryptocurrencies
				</Box>
				<Flex
					flexDir={{ base: 'column', md: 'row' }}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					{listJourney.map((journey, index) => (
						<Box
							key={journey.id}
							marginBottom={{ base: '60px', md: 0 }}
							data-aos="fade-up"
							data-aos-duration={`1${index + 1}00`}
						>
							<Image
								src={`/assets/images/homepage/journey-${journey.id}.png`}
							/>
							<Box
								textAlign={'center'}
								my={{ base: '10px', md: '20px' }}
								fontSize={{ base: '20px', md: '24px' }}
							>
								{journey.title}
							</Box>
							<Box maxW={'301px'} color={'#737283'} textAlign={'center'}>
								{journey.description}
							</Box>
						</Box>
					))}
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default StartYourJourney;

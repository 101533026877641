import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	padding-block: 100px;
`;

const listJourney = [
	{
		id: 1,
		title: 'SOC 1 Type 2 Exam SOC 2 Type 2 Exam',
		description:
			'We were the first crypto exchange and custodian in the world to obtain our SOC 1 Type 2 & SOC 2 Type 2 certifications. This exam was conducted by Deloitte',
	},
	{
		id: 2,
		title: 'ISO / IEC 27001:2013',
		description:
			'Validates that Metaniex operates an Information Security Management System (ISMS) that conforms to the requirements of ISO/IEC 27001:2013',
	},
	{
		id: 3,
		title: 'PCI DSS ROC AOC & Attested SAQ',
		description:
			'Evidence of our commitment to maintaining compliance with an information security standard that protects cardholder data both when we accept debit cards on our platform',
	},
];

const OurCertifications = () => {
	return (
		<Wrapper>
			<Container>
				<Box
					fontSize={{ base: '20px', md: '30px' }}
					textAlign={'center'}
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					Our Certifications
				</Box>
				<Box
					maxW={'889px'}
					mx={'auto'}
					textAlign={'center'}
					color={'#737283'}
					fontWeight={400}
					fontSize={{ base: '16px', md: '18px' }}
					mb={'20px'}
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					Metaniex is one the safest cryptocurrency exchanges where you can buy,
					sell, store, and accrue staking rewards on cypto and other
					cryptocurrencies
				</Box>
				<Flex
					flexDir={{ base: 'column', md: 'row' }}
					justifyContent={'space-between'}
				>
					{listJourney.map((journey, index) => (
						<Box
							key={journey.id}
							marginBottom={{ base: '60px', md: 0 }}
							bg="linear-gradient(184deg, rgba(255, 255, 255, 0) -21.23%, rgba(255, 255, 255, 0.096) 78.13%)"
							paddingY={'40px'}
							px={'28px'}
							display={'flex'}
							flexDir={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							borderRadius={'20px'}
							data-aos="fade-up"
							data-aos-duration={`1${index + 1}00`}
						>
							<Image
								src={`/assets/images/homepage/certifications-${journey.id}.png`}
							/>
							<Box
								textAlign={'center'}
								my={{ base: '10px', md: '20px' }}
								fontSize={{ base: '20px', md: '24px' }}
								maxW={'328px'}
							>
								{journey.title}
							</Box>
							<Box maxW={'328px'} color={'#737283'} textAlign={'center'}>
								{journey.description}
							</Box>
						</Box>
					))}
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default OurCertifications;

export default function QuestionIcon() {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.78782 11.1574C8.78594 11.1574 8.78407 11.1574 8.78219 11.1574C8.43719 11.1543 8.15969 10.8721 8.16282 10.5268L8.16563 10.2143C8.16563 10.1965 8.16657 10.1784 8.16844 10.1606C8.255 9.2515 8.85844 8.69431 9.34375 8.2465C9.50813 8.09463 9.66375 7.95119 9.79625 7.8015C9.95782 7.61931 10.1922 7.24619 9.94563 6.79588C9.66157 6.27588 8.96782 6.12869 8.42875 6.25213C7.86563 6.38088 7.65813 6.86181 7.58438 7.13681C7.495 7.47025 7.15219 7.66806 6.81875 7.579C6.48532 7.48963 6.2875 7.14681 6.37657 6.81338C6.62563 5.88306 7.27188 5.23463 8.14938 5.03369C9.33032 4.764 10.5197 5.24119 11.0419 6.19588C11.4766 6.99088 11.3575 7.92369 10.7309 8.63056C10.5569 8.82681 10.3709 8.99869 10.1909 9.16463C9.7425 9.57869 9.46219 9.85556 9.41469 10.2552L9.41219 10.5374C9.40969 10.8815 9.13032 11.1574 8.78782 11.1574Z"
				fill="#737283"
			/>
			<path
				d="M8.78751 13.0312C8.62501 13.0312 8.46251 12.9656 8.34688 12.8499C8.22813 12.7343 8.16251 12.5718 8.16251 12.4062C8.16251 12.2437 8.22813 12.0812 8.34688 11.9656C8.57813 11.7343 8.99688 11.7343 9.22813 11.9656C9.34688 12.0812 9.41251 12.2437 9.41251 12.4062C9.41251 12.5718 9.34688 12.7312 9.23126 12.8499C9.11251 12.9656 8.95313 13.0312 8.78751 13.0312Z"
				fill="#737283"
			/>
			<path
				d="M8.97594 17.124C8.265 17.124 7.56031 17.0315 6.88125 16.8487C3.955 16.0615 1.66375 13.6375 1.04406 10.6728C0.412502 7.65248 1.57813 4.47748 4.01407 2.58404C5.42219 1.48936 7.19813 0.88623 9.015 0.88623C10.2728 0.88623 11.5219 1.17873 12.6275 1.73154C15.3578 3.09748 17.1219 5.95123 17.1219 9.00217C17.1219 11.4915 15.9472 13.8909 13.9797 15.4203C12.5666 16.519 10.7894 17.124 8.97594 17.124ZM9.01531 2.13623C7.475 2.13623 5.97125 2.64561 4.78156 3.57092C2.72 5.17342 1.73313 7.86061 2.26781 10.4169C2.79219 12.9247 4.73063 14.9756 7.20625 15.6415C7.77938 15.7956 8.375 15.874 8.97594 15.874C10.5128 15.874 12.0175 15.3625 13.2128 14.4334C14.8781 13.139 15.8722 11.1087 15.8722 9.00217C15.8722 6.42061 14.3794 4.00561 12.0688 2.84967C11.1356 2.38311 10.08 2.13623 9.01531 2.13623Z"
				fill="#737283"
			/>
		</svg>
	);
}

import React from 'react';
import Type from './Type';
import CryptoTabs from './CryptoTabs';
import FilterAndRefresh from './FilterAndRefresh';
import TradeData from './TradeData';
import Container from '../../layouts/Container';
import { Box, Show } from '@chakra-ui/react';

export default function Body() {
	return (
		<div>
			<Type />
			<CryptoTabs />
			<Box py={{ base: '0px', lg: '30px' }}>
				<Show above="md">
					<FilterAndRefresh />
				</Show>
				<Container>
					<TradeData />
				</Container>
			</Box>
		</div>
	);
}

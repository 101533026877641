import { Box, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import Container from '../../layouts/Container';

const Wrapper = styled(Box)`
	margin-top: 118px;
	background-color: #091229;
`;

const AtMetaniex = () => {
	return (
		<Wrapper py={'100px'}>
			<Container>
				<Box
					textAlign={'center'}
					fontSize={{ base: '20px', md: '30px' }}
					mb={'10px'}
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					At Metaniex!
				</Box>
				<Box
					fontSize={{ base: '20px', md: '30px' }}
					color="#BDBCC7"
					fontWeight={300}
					lineHeight={'37px'}
					fontStyle="normal"
					data-aos="fade-up"
					data-aos-duration={'2000'}
				>
					“<span style={{ fontWeight: 500 }}>W</span>e hold trust as the
					cornerstone of our development, valuing it above all else. We
					understand that trust is earned through unwavering commitment to
					transparency, reliability, and ethical business practices. It is our
					unwavering dedication to upholding trust that drives us to deliver on
					our promises, safeguard customer data, provide exceptional products
					and services, and maintain open communication with our stakeholders.
					We are honored to be entrusted with the confidence of our customers,
					employees, and partners, and we pledge to continue fostering trust
					through our professional and inspirational actions”.
					<Text my={'60px'}>
						From day one, Metaniex has prioritized the security of your assets.
						<br /> We never have and never will compromise on that.
					</Text>
					<Flex
						justifyContent={'flex-end'}
						data-aos="fade-up"
						data-aos-duration={'2000'}
					>
						<Image src="/assets/images/homepage/Metaniex.svg" />
					</Flex>
				</Box>
			</Container>
		</Wrapper>
	);
};

export default AtMetaniex;

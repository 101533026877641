import React from 'react';
import { Flex, Image, Box, Button } from '@chakra-ui/react';
import Container from '../../layouts/Container';
import TableUI from '../UI/TableUI';

const columns = [
	{
		key: 'name',
		title: 'Name',
		isNumeric: false,
		render: (name: string) => (
			<Flex alignItems={'center'}>
				<Image
					mr={'12px'}
					src={`/assets/images/homepage/coin-icon/${name.toLocaleLowerCase()}.svg`}
				/>
				<Box>{name}</Box>
			</Flex>
		),
	},
	{
		key: 'lastPrice',
		title: 'Last Price',
		isNumeric: false,
		render: (value: any) => value,
	},
	{
		key: '_24hChange',
		title: '24h Change',
		isNumeric: false,
		render: (value: any) => <Box color={'#CF304A'}>{value}</Box>,
	},
	{
		key: '_24hVol',
		title: '24h Vol',
		isNumeric: false,
		render: (value: any) => value,
	},
	{
		key: 'trade',
		title: 'Trade',
		isNumeric: true,
		render: () => (
			<Button
				fontSize={'16px'}
				py={'5px'}
				px={'20px'}
				lineHeight={'24px'}
				bg={'#03A66D'}
				fontWeight={500}
				color={'white'}
			>
				Trade
			</Button>
		),
	},
];

const dataTable = [
	{
		key: 1,
		name: 'BNB',
		lastPrice: '$326.8',
		_24hChange: '-1.60%',
		_24hVol: '$326.8',
		trade: 1,
	},
	{
		key: 2,
		name: 'BNB',
		lastPrice: '$326.8',
		_24hChange: '-1.60%',
		_24hVol: '$326.8',
		trade: 1,
	},
	{
		key: 3,
		name: 'BNB',
		lastPrice: '$326.8',
		_24hChange: '-1.60%',
		_24hVol: '$326.8',
		trade: 1,
	},
];

const PopularCryptocurrencies = () => {
	return (
		<Box mt={'118px'}>
			<Container>
				<Flex justifyContent={'space-between'} alignItems={'flex-end'}>
					<Box
						fontSize={{ base: '20px', md: '30px' }}
						data-aos="fade-right"
						data-aos-duration="1000"
					>
						Popular cryptocurrencies
					</Box>
					<Box
						display={{ base: 'none', md: 'flex' }}
						alignItems={'center'}
						cursor={'pointer'}
						data-aos="fade-left"
						data-aos-duration="1000"
					>
						<Box mr={'18.4px'}>View more markets </Box>
						<Box>
							<Image src="/assets/images/homepage/arrowRight-icon.svg" />
						</Box>
					</Box>
				</Flex>

				<Box data-aos="fade-up" data-aos-duration="1000">
					<TableUI dataTable={dataTable} columns={columns} />
				</Box>

				<Box
					data-aos="fade-up"
					data-aos-duration="1000"
					border={'0.6px solid #737283'}
					py={'16px'}
					maxW={'212.52px'}
					mx={'auto'}
					borderRadius={'8px'}
					mt={'20px'}
					display={{ md: 'none' }}
				>
					<Box
						display={{ base: 'flex', md: 'none' }}
						alignItems={'center'}
						cursor={'pointer'}
						justifyContent={'center'}
					>
						<Box mr={'18.4px'}>View more markets </Box>
						<Box>
							<Image src="/assets/images/homepage/arrowRight-icon.svg" />
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default PopularCryptocurrencies;

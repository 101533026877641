import { Box, chakra } from '@chakra-ui/react';
import React from 'react';

const Content = chakra(Box, {
	baseStyle: {
		maxW: 1248,
		mx: 'auto',
		px: { base: '18px', xl: 0 },
		height: '100%',
	},
});

const Container = ({ children }: { children: React.ReactNode }) => {
	return <Content>{children}</Content>;
};

export default Container;

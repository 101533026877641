import React from 'react';
import Banner from '../components/BuySell/Banner';
import Body from '../components/BuySell/Body';

export default function BuySellPage() {
	return (
		<div>
			<Banner />
			<Body />
		</div>
	);
}

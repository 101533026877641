import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import Container from '../../layouts/Container';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react';

const questionList = [
	{
		key: 1,
		title: 'Industry leading security',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	},
	{
		key: 2,
		title: 'Crypto Security',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	},
	{
		key: 3,
		title: 'Account Security',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
	},
];

const WhyChooseMetaniex = () => {
	return (
		<Box mt={'156px'}>
			<Container>
				<Box textAlign={'center'} data-aos="fade-up" data-aos-duration={'2000'}>
					<Box fontSize={'30px'} mb={'8px'}>
						Why choose Metaniex?
					</Box>
					<Box maxW={'791px'} mx="auto" color="#737283">
						Metaniex is one the safest cryptocurrency exchanges where you can
						buy, sell, store, and accrue staking rewards on cypto and other
						cryptocurrencies
					</Box>
				</Box>

				<Flex
					mt={'30px'}
					alignItems={'center'}
					justifyContent={'space-between'}
					flexDir={{ base: 'column', md: 'row' }}
				>
					<Box
						w={{ base: '100%', md: '40%' }}
						data-aos="fade-up"
						data-aos-duration={'2000'}
					>
						<Image src="/assets/images/homepage/whyChooseMetaniex.png" />
					</Box>
					<Box
						w={{ base: '100%', md: '60%' }}
						data-aos="fade-up"
						data-aos-duration={'2000'}
					>
						<Accordion w={'100%'}>
							{questionList.map((question) => (
								<AccordionItem
									py={'14px'}
									key={question.key}
									borderWidth={'1px'}
									borderRadius={'6px'}
									mb={'10px'}
								>
									<h2>
										<AccordionButton>
											<Box as="span" flex="1" textAlign="left">
												{question.title}
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>{question.description}</AccordionPanel>
								</AccordionItem>
							))}
						</Accordion>
						<Box maxW={'719px'} mx={'auto'} color="#737283" mt={'20px'}>
							Metaniex is one the safest cryptocurrency exchanges where you can
							buy, sell, store, and accrue staking rewards on cypto and other
							cryptocurrencies
						</Box>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default WhyChooseMetaniex;

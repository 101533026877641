import React from 'react';
import Intro from '../components/Home/Intro';
import Statistical from '../components/Home/Statistical';
import AdsCarousel from '../components/Home/AdsCarousel';
import PopularCryptocurrencies from '../components/Home/PopularCryptocurrencies';
import BeginnersGuide from '../components/Home/BeginnersGuide';
import StartYourJourney from '../components/Home/StartYourJourney';
import EasyToInvest from '../components/Home/EasyToInvest';
import SafeAndSecure from '../components/Home/SafeAndSecure';
import WhyChooseMetaniex from '../components/Home/WhyChooseMetaniex';
import OurCertifications from '../components/Home/OurCertifications';
import AtMetaniex from '../components/Home/AtMetaniex';
import RegisterNow from '../components/Home/RegisterNow';
import { Box } from '@chakra-ui/react';

export default function HomePage() {
	return (
		<Box overflowX={'hidden'}>
			<Intro />
			<Statistical />
			<AdsCarousel />
			<PopularCryptocurrencies />
			<BeginnersGuide />
			<StartYourJourney />
			<EasyToInvest />
			<SafeAndSecure />
			<WhyChooseMetaniex />
			<OurCertifications />
			<AtMetaniex />
			<RegisterNow />
		</Box>
	);
}

import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const LayoutWrapper = styled(Box)`
	min-height: 100vh;
	font-family: 'Roboto';
`;

export const NavWrapper = styled(Box)`
	width: 100%;
	background-color: #09122980;
	font-weight: 400;
	font-size: 14px;
`;

export const NavCenterWrapper = styled(Flex)`
	width: 100%;
	max-width: 1248px;
`;

export const LinkActive = styled(Text)`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	cursor: pointer;
`;

export const LinkItem = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

export const FooterWrapper = styled(Box)`
	background-color: #091229;
`;

export const FooterFlex = styled(Flex)`
	max-width: 1248px;
	margin: auto;
`;

export const FooterTitle = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	text-transform: uppercase;
	color: #ffffff;
`;

export const SecondaryText = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 26px;
	color: #737283;
`;

export const FooterHeading = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	text-transform: uppercase;
	color: #b1b2b2;
	margin-bottom: 10px;
`;

export const COInformation = styled(Box)`
	text-align: center;
	background-color: #050713;
	padding: 20px 10px;
`;

export const BodyWrapper = styled(Box)<any>`
	min-height: ${(props) => `calc(100vh - ${props.minHeight}px)` || 'auto'};
`;

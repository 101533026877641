import React from 'react';
import { chakra } from '@chakra-ui/system';
import { Box, Flex, Text } from '@chakra-ui/layout';
import Container from '../../layouts/Container';
import { Button, Checkbox, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

// style component atoms
const Wrapper = chakra(Box, {
	baseStyle: {
		minH: '100vh',
		h: { base: 'auto', md: '100vh' },
		w: '100%',
		background: {
			base: 'url(/assets/images/homepage/intro-bg-mobile.png)',
			md: 'url(/assets/images/homepage/intro-bg.png)',
		},
		bgSize: { md: 'cover' },
		bgRepeat: { md: 'no-repeat' },
		color: 'white',
		position: { base: 'relative' },
	},
});

const Title = chakra(Box, {
	baseStyle: {
		fontSize: { base: '24px', md: '38px' },
		lineHeight: { base: '40px', md: '48px' },
		mb: { base: '13px', md: '44px' },
		textAlign: { base: 'center', md: 'left' },
	},
});

const Subtitle = chakra(Box, {
	baseStyle: {
		fontSize: { base: '16px', md: '18px' },
		lineHeight: { base: '20px' },
		mb: { base: '25px' },
		textAlign: { base: 'center', md: 'left' },
	},
});

const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '14px',
		fontSize: '16px',
		color: 'black',
		fontWeight: 500,
		maxW: { base: '100%', md: '384px' },
		w: '100%',
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});

const Content = chakra(Box, {
	baseStyle: {
		mb: '25px',
		w: '100%',
	},
});

type FormFields = {
	key: number;
	lable: string | React.ReactNode;
	name: string;
	type: string;
	placeholde: string;
};

// render ui
const Authentication = ({
	title,
	titleButton,
	subtitle,
	formFields,
	onSubmit,
}: {
	title: string;
	titleButton: string | React.ReactNode;
	subtitle: string | React.ReactNode;
	formFields: FormFields[];
	onSubmit: (value: any) => void;
}) => {
	const { register, handleSubmit } = useForm();
	return (
		<Wrapper>
			<Container>
				<Flex
					justifyContent={{ base: 'center', md: 'space-between' }}
					alignItems={'center'}
					height={'100%'}
					flexDirection={{ base: 'column', md: 'row' }}
					pt={{ base: '70px', md: 0 }}
				>
					<Flex
						flexDirection={'column'}
						alignItems={{ base: 'center', md: 'flex-start' }}
						data-aos="fade-right"
						data-aos-duration="1000"
						w={{ base: '100%', md: 'auto' }}
					>
						<form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
							<Title>{title}</Title>
							<Subtitle>{subtitle}</Subtitle>
							<Content>
								{formFields.map((field) => (
									<Box key={field.key} w={'100%'}>
										{field.type === 'checkbox' && (
											<Checkbox colorScheme={'yellow'}>{field.lable}</Checkbox>
										)}

										{(field.type === 'email' ||
											field.type === 'text' ||
											field.type === 'password') && (
											<>
												<Text color={'#888797'} mb={'13px'}>
													{field.lable}
												</Text>
												<Input
													type={field.type}
													{...register(field.name)}
													w={{ base: '100%', md: '386px' }}
													py={'14px'}
													background={'white'}
													borderRadius={'36px'}
													_placeholder={{
														color: '#BAB9D0',
													}}
													color="black"
													focusBorderColor="#FFBF1A"
													placeholder={field.placeholde}
													mb={'16px'}
												/>
											</>
										)}
									</Box>
								))}
							</Content>
							<ButtonAuth type="submit">{titleButton}</ButtonAuth>
						</form>
					</Flex>
					<Box data-aos="fade-left" data-aos-duration="1000">
						<img src="/assets/images/homepage/Astronaut.png" alt="" />
					</Box>
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default Authentication;
